import { LayoutAuth, LayoutDefault } from "@/components/layouts";
import { AUTH_TYPE } from "@/util/utils";

export const publicRoute = [
  {
    path: "*",
    component: () => import("@/views/error/NotFound.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "Not Found",
    },
    component: () => import("@/views/error/NotFound.vue"),
  },
  {
    path: "/auth",
    component: LayoutAuth,
    meta: {
      title: "Login",
    },
    redirect: "/auth/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        meta: {
          title: "登录",
        },
        component: () => import("@/views/auth/LoginPage.vue"),
      },
    ],
  },
  {
    path: "/500",
    name: "500",
    meta: {
      title: "Server Error",
    },
    component: () => import("@/views/error/ErrorPage.vue"),
  },
];

export const protectedRoute = [
  {
    path: "/",
    component: LayoutDefault,
    meta: {
      title: "主页",
      group: "apps",
      icon: "",
    },
    redirect: "/overview",
    children: [
      {
        path: "/overview",
        component: () => import("@/views/TodayOverview.vue"),
        meta: {
          title: "总览",
          icon: "mdi-view-dashboard",
          info: "统计自AF回传的BigQuery数据",
        },
      },
      {
        path: "/marketOverviewChart",
        component: () => import("@/views/MarketOverviewChart.vue"),
        meta: {
          title: "市场总览",
          icon: "mdi-chart-areaspline",
          auth: AUTH_TYPE.MARKET,
        },
      },
      {
        path: "/marketOverviewTable",
        component: () => import("@/views/MarketOverviewTable.vue"),
        meta: {
          title: "市场总览(表格)",
          icon: "mdi-table",
          auth: AUTH_TYPE.MARKET,
        },
      },
      {
        path: "/realtime",
        component: () => import("@/views/database/RealtimePage.vue"),
        meta: {
          title: "实时报表",
          icon: "mdi-timer-sync-outline",
          info: "查询24小时内，按小时聚合数据，页面会自动刷新",
        },
      },
      {
        path: "/retained",
        meta: {
          title: "留存率",
          icon: "mdi-account-reactivate-outline",
        },
        component: () => import("@/views/database/PlayerRetained"),
      },
      {
        path: "/progression",
        meta: {
          title: "关卡通过率",
          icon: "mdi-clock-time-nine-outline",
        },
        component: () => import("@/views/direct/StageProgression"),
      },
      {
        path: "/ad/rewardedAndInterstitial",
        meta: {
          title: "激励&插屏广告数",
          icon: "mdi-advertisements",
          info: "计算平均广告数时，会基于同期群新增人数与当天广告数进行计算。",
        },
        component: () => import("@/views/database/RewardedAndInterstitial"),
      },
      {
        path: "/ad/rewardedAndInterstitialRetained",
        meta: {
          title: "激励 & 插屏广告数(日活)",
          icon: "mdi-advertisements",
          info: "计算平均广告数时，会基于当天的留存人数与广告数进行计算。",
        },
        component: () =>
          import("@/views/database/RewardedAndInterstitialRetained"),
      },
      {
        path: "/adRevenue",
        meta: {
          title: "平均广告收入",
          icon: "mdi-advertisements",
        },
        component: () => import("@/views/database/AdRevenue"),
      },
      {
        path: "/ad/rewardedDistribution",
        meta: {
          title: "激励广告分布",
          icon: "mdi-distribute-vertical-center",
        },
        component: () => import("@/views/direct/RewardedDistribution"),
      },
      {
        path: "/ad/deviation",
        meta: {
          title: "激励广告标准差",
          icon: "mdi-poll",
        },
        component: () => import("@/views/direct/AdDeviation"),
      },
      {
        path: "/purchase",
        meta: {
          title: "付费项分布",
          icon: "mdi-cart",
        },
        component: () => import("@/views/direct/PurchasePage"),
      },
      {
        path: "/paidRetained",
        meta: {
          title: "付费留存率",
          icon: "mdi-cart",
        },
        component: () => import("@/views/database/PaidRetained.vue"),
      },
      {
        path: "/iaaiap",
        meta: {
          title: "IAA & IAP报表",
          icon: "mdi-currency-usd",
          info: "统计自AF回传的BigQuery数据",
        },
        component: () => import("@/views/database/IaaAndIapPage"),
      },
      {
        path: "/roi",
        meta: {
          title: "ROI报表",
          icon: "mdi-currency-usd",
          auth: AUTH_TYPE.MARKET,
          info: "统计自各个广告平台API聚合后保存在AWS S3的数据",
        },
        component: () => import("@/views/database/RoiPage"),
      },
      {
        path: "/dailyActiveRoi",
        meta: {
          title: "ROI(日活)报表",
          icon: "mdi-currency-usd",
          auth: AUTH_TYPE.MARKET,
          info: "Cost数据统计自各个广告平台API聚合后保存在AWS S3的数据\n收入数据统计自AF回传的BigQuery数据",
        },
        component: () => import("@/views/database/DailyActiveRoi"),
      },
      {
        path: "/skanCostRoi",
        meta: {
          title: "ROI(日活)报表-SKAN",
          icon: "mdi-currency-usd",
          auth: AUTH_TYPE.MARKET,
        },
        component: () => import("@/views/database/SkanCostRoi.vue"),
      },
      {
        path: "/upgrade",
        meta: {
          title: "升级项分布",
          icon: "mdi-chevron-triple-up",
        },
        component: () => import("@/views/direct/UpgradePage"),
      },
      {
        path: "/upgradeLevel",
        meta: {
          title: "升级项等级分布",
          icon: "mdi-chevron-triple-up",
        },
        component: () => import("@/views/direct/UpgradeLevel"),
      },
      {
        path: "/playTime",
        meta: {
          title: "人均游玩时长",
          icon: "mdi-cellphone-wireless",
        },
        component: () => import("@/views/database/PlayTime.vue"),
      },
      {
        path: "/asset",
        meta: {
          title: "广告素材报表",
          icon: "mdi-currency-usd",
          auth: AUTH_TYPE.MARKET,
        },
        component: () => import("@/views/database/AssetPage"),
      },
      {
        path: "/weekReport",
        meta: {
          title: "周报&月报",
          icon: "mdi-file-table-outline",
          auth: AUTH_TYPE.MARKET,
        },
        component: () => import("@/views/WeekReport"),
      },
      {
        path: "/projectOverview",
        meta: {
          title: "项目总览",
          icon: "mdi-file-table-outline",
          auth: AUTH_TYPE.ADMIN,
        },
        component: () => import("@/views/database/ProjectOverview.vue"),
      },
    ],
  },
  {
    path: "/management",
    component: LayoutDefault,
    meta: {
      title: "管理",
      icon: "",
    },
    redirect: "/management/project",
    children: [
      {
        path: "/management/project",
        name: "projectManagement",
        meta: {
          title: "项目管理",
          auth: AUTH_TYPE.SUPER_ADMIN,
        },
        component: () => import("@/views/auth/ProjectManagement"),
      },
      {
        path: "/management/auth",
        name: "authManagement",
        meta: {
          title: "权限管理",
          auth: AUTH_TYPE.SUPER_ADMIN,
        },
        component: () => import("@/views/auth/AuthManagement"),
      },
      {
        path: "/management/testUser",
        name: "testUserManagement",
        meta: {
          title: "测试用户&版本管理",
          auth: AUTH_TYPE.ADMIN,
        },
        component: () => import("@/views/auth/TestManagement"),
      },
      {
        path: "/management/attribution",
        name: "attributionManagement",
        meta: {
          title: "概率归因渠道管理",
          auth: AUTH_TYPE.ADMIN,
        },
        component: () => import("@/views/auth/AttributeManagement.vue"),
      },
      {
        path: "/management/task",
        name: "runTask",
        meta: {
          title: "执行任务",
          auth: AUTH_TYPE.ADMIN,
        },
        component: () => import("@/views/auth/RunTaskPage"),
      },
    ],
  },
];
