import Vue from "vue";
import Router from "vue-router";
import { publicRoute, protectedRoute } from "./config";
import store from "@/store";
import NProgress from "nprogress";
import { AUTH_TYPE } from "@/util/utils";

const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);
const router = new Router({
  mode: "hash",
  linkActiveClass: "active",
  routes: routes,
});
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.name !== "login") {
    let user = store.getters.getUser || {};
    if (user.token && user.openId) {
      if (user.auth <= (to.meta.auth ?? AUTH_TYPE.PRODUCT)) {
        next();
      } else {
        Vue.toasted.error(to.meta.title + " 页面权限不足");
        if (from.path !== "/overview") {
          next("/overview");
        } else {
          NProgress.done();
        }
      }
    } else {
      next("/auth/login");
    }
  } else {
    next();
  }
  //auth route is authenticated
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
