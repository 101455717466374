<template>
  <v-app class="app">
    <app-side-bar
      class="app--drawer"
      ref="drawer"
      @projectChange="
        () => {
          this.componentKey += 1;
        }
      "
    />
    <app-top-toolbar
      class="app--toolbar"
      @side-icon-click="handleDrawerVisible"
    />
    <v-main :key="componentKey">
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <router-view :key="key" />
      </div>
    </v-main>
    <!-- Go to top -->
    <app-to-top />
    <v-tooltip bottom v-if="info">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="info-icon">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span>{{ info }}</span>
    </v-tooltip>
  </v-app>
</template>

<script>
import AppSideBar from "@/components/AppSideBar";
import AppTopToolbar from "@/components/AppTopToolbar";
import AppToTop from "@/components/AppToTop";
export default {
  components: {
    AppToTop,
    AppTopToolbar,
    AppSideBar,
  },

  data() {
    return {
      showDrawer: true,
      componentKey: 0,
    };
  },
  methods: {
    handleDrawerVisible() {
      this.$refs.drawer.toggleDrawer();
    },
  },
  computed: {
    key() {
      return this.$route.path;
    },
    info() {
      return this.$route.meta.info;
    },
  },
  created() {},
};
</script>

<style lang="sass" scoped>
.page-wrapper
  min-height: calc(100vh - 112px - 48px)

.info-icon
  position: fixed
  top: 63px
  right: -1px
  z-index: 9999
</style>
